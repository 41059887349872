@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-BoldItalic.otf) format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-Black.otf) format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-BlackItalic.otf) format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-Italic.otf) format("opentype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-LightItalic.otf) format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-MediumItalic.otf) format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Formular";
  src: local("Formular"), url(./assets/fonts/Formular-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@layer base {
  html {
    font-family: Formular, system-ui, sans-serif;
  }
  body {
    font-family: Formular, system-ui, sans-serif;
  }
  .phone-input {
    @apply focus:ring-sky;
  }
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #2f3441;
    color: #2f3441;
    animation: dotTyping 1.5s infinite linear;
  }

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 #2f3441, 9999px 0 0 0 #2f3441, 10014px 0 0 0 #2f3441;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 #2f3441, 9999px 0 0 0 #2f3441, 10014px 0 0 0 #2f3441;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 #2f3441, 9999px 0 0 0 #2f3441, 10014px 0 0 0 #2f3441;
    }
    50% {
      box-shadow: 9984px 0 0 0 #2f3441, 9999px -10px 0 0 #2f3441, 10014px 0 0 0 #2f3441;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 #2f3441, 9999px 0 0 0 #2f3441, 10014px 0 0 0 #2f3441;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 #2f3441, 9999px 0 0 0 #2f3441, 10014px -10px 0 0 #2f3441;
    }
    100% {
      box-shadow: 9984px 0 0 0 #2f3441, 9999px 0 0 0 #2f3441, 10014px 0 0 0 #2f3441;
    }
  }

  .fade-in {
    position: relative;
    animation: fadein 1s ease-in;
  }

  .fade-out {
    position: relative;
  }

  @keyframes fadein {
    from {
      opacity: 0;
      top: -20px;
    }
    to {
      opacity: 1;
      top: 0px;
    }
  }
}

.img-gradient {
  position: relative;
  display: inline-block;
}

.img-gradient:after {
  content: "";
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.4))
  );
}
.img-gradient img {
  display: block;
}

.home-img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.4))
  );
}
.home-img-gradient img {
  display: block;
}

.correctAnswer {
  color: white;
  background: rgb(23, 167, 23);
}

.incorrectAnswer {
  background: red;
  color: white;
}

/* slide down animation */

.slide-down {
  animation: slide-down 1.5s ease-in-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.activity-stripes {
  background: repeating-linear-gradient(45deg, #4fc4ff, #4fc4ff 15px, transparent 15px, transparent 25px);
}

.mini-activity-stripes {
  background: repeating-linear-gradient(45deg, #4fc4ff, #4fc4ff 5px, transparent 5px, transparent 10px);
}

.performance-stripes {
  background: repeating-linear-gradient(45deg, #469743, #469743 15px, #ecfad3 15px, #ecfad3 25px);
}

.mini-performance-stripes {
  background: repeating-linear-gradient(45deg, #469743, #469743 5px, #ecfad3 5px, #ecfad3 10px);
}

.satisfaction-stripes {
  background: #2d5934;
  background-image: radial-gradient(white 2px, transparent 0);
  background-size: 10px 10px;
  background-position: -21px -21px;
}
